<template>

  <div class="">
    <custom-alert v-if="displayAlert==true" :message="alertMessage"
                  v-on:showedAlert="resetAlert"></custom-alert>
    <!-- Content Header (Page header) -->
    <div class="">
      <!-- <button class="btn btn-primary" @click="showText= !showText">Click Me</button> -->
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Main row -->
          <div class="row">
            <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
              <div class="card ">
                <div class="card-body table-responsive table-head">
                  <div class="row bor-bot">
                    <div class="col-md-8 pl-0">
                      <h1 class="m-0 text-dark pt-2 pb-4 ">Player Transfer History List</h1>
                    </div>
                    <div class="col-md-4">
                      <div class="text-right pb-3">
                        <ol class="breadcrumb float-sm-right">
                          <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                          <li class="breadcrumb-item">
                            <Breadcrumbs/>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive mt-3" id="member_reg_report_table">
                    <div class="data-tablebutn pb-3 mr-3">
                      <b-button class="btn-primary btn-darkblue btn mr-1" :class="{ 'filter-applied': filtersApplied }" v-b-modal.modal-member-report>
                        <i class="fas fa-sliders-h"></i> Filters
                      </b-button>
                      <b-button class="btn-primary btn-darkblue btn" @click="refreshList" v-if="filtersApplied">
                        <i class="fas fa-redo"></i> Reset
                      </b-button>
                      <b-button class="ml-1 btn-primary btn-darkblue btn" @click="exportCsv" :disabled="exportProgress">
                        <i class="fas" :class="[ exportProgress ? 'fa-circle-notch fa-spin' : 'fa-file-download' ]"></i> Export
                      </b-button>
                    </div>
                    <div class="search-box">
                      <input type="text" class="input-field form-control " style="width:100%; max-width:234px" name="general_search" id="general_search" @keyup="queryForKeywords">
                      <i class="fas fa-search" style="position:absolute"></i>
                    </div>
                    <table class="table table-sm table-bordered table-hover" id="list_table">
                      <thead class="thead-dark">
                      <tr>
                        <th>Member ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Player Name</th>
                        <th>Source Team</th>
                        <th>Source Team ID</th>
                        <th>Source Age Division</th>
                        <th>New Team</th>
                        <th>New Team ID</th>
                        <th>New Age Division</th>
                        <th>Transfer Date</th>
                        <th>Tenure</th>
                        <th>Transfer Status</th>
                      </tr>
                      </thead>
                      <tbody>
                      <!-- Data will be placed here -->

                      </tbody>
                    </table>
                  </div>
                  <b-modal id="modal-member-report" v-bind:hide-footer="true" ref="modal-member-report" size="lg"  title="Advanced Search">
                    <form ref="form" method="post" @submit.prevent="advancedSearch()">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Member Id</label>
                            <input type="text" id="confirmation_code" class="form-control" v-model="searchForm.confirmation_code">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Player Name</label>
                            <input type="text" id="player_name" class="form-control" v-model="searchForm.player_name">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="transfer_initiator" class="form-label">Transfer Initiator</label>
                            <select class="form-control" id="transfer_initiator" v-model="searchForm.transfer_initiator">
                              <option value="">Select Team</option>
                              <option v-for="(TeamData,key) in teams" :key="key" :value="TeamData.id">
                                {{ TeamData.text }}
                              </option>
                            </select>
                            <span class="text-sm text-danger"></span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="transfer_team_to" class="form-label">Transfer Team - To</label>
                            <select class="form-control" id="transfer_team_to" v-model="searchForm.transfer_team_to">
                              <option value="">Select Team</option>
                              <option v-for="(TeamData,key) in teams" :key="key" :value="TeamData.id">
                                {{ TeamData.text }}
                              </option>
                            </select>
                            <span class="text-sm text-danger"></span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="transaction_start_date" class="form-label">Transfer Date - From</label>
                            <div class="input-group birthdate">
                              <div class="birthdaypicker">
                                <date-pick
                                    v-model="searchForm.transfer_start_date"
                                    :format="'MM/DD/YYYY'"
                                    :displayFormat="'MM/DD/YYYY'"
                                    v-mask="'##/##/####'"
                                    :parseDate="parseDatePick"
                                ></date-pick>
                              </div>
                            </div>
                            <input id="transaction_start_date" type="hidden" class="form-control" :value="transfer_start_date">
                            <div class="text-sm text-danger">{{ errors.transfer_start_date }}</div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="transaction_end_date" class="form-label">Transfer Date - To</label>
                            <div class="input-group birthdate">
                              <div class="birthdaypicker">
                                <date-pick
                                    v-model="searchForm.transfer_end_date"
                                    :format="'MM/DD/YYYY'"
                                    :displayFormat="'MM/DD/YYYY'"
                                    v-mask="'##/##/####'"
                                    :parseDate="parseDatePick"
                                ></date-pick>
                              </div>
                            </div>
                            <input id="transaction_end_date" type="hidden" class="form-control" :value="transfer_end_date">
                            <div class="text-sm text-danger">{{ errors.transfer_end_date }}</div>
                          </div>
                        </div>
                        <div class="col-md-6" >
                          <div class="form-group">
                            <label class="form-label">Transfer League - From</label>
                            <select id="season" class="form-control" v-model="searchForm.transfer_team_from_league">
                              <option selected="selected" value="">Select League</option>
                              <option v-for="(league, id) in leagues" :value="league.id" :key="id">{{ league.name }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" >
                          <div class="form-group">
                            <label class="form-label">Transfer League - To</label>
                            <select id="league" class="form-control" v-model="searchForm.transfer_team_to_league">
                              <option selected="selected" value="">Select League</option>
                              <option v-for="(league, id) in leagues" :value="league.id" :key="id">{{ league.name }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Transfer Division - From</label>
                            <select class="form-control" id="age_division" v-model="searchForm.transfer_team_from_division">
                              <option value="">Select Age Division</option>
                              <option v-for="(discipline, id) in sportDisciplinesSelect2" :value="discipline.id" :key="id">{{ discipline.text }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Transfer Division - To</label>
                            <select class="form-control" id="age_division" v-model="searchForm.transfer_team_to_division">
                              <option value="">Select Age Division</option>
                              <option v-for="(discipline, id) in sportDisciplinesSelect2" :value="discipline.id" :key="id">{{ discipline.text }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Transfer Status</label>
                            <select id="status" name="status" class="form-control" v-model="searchForm.status">
                              <option value="">Select</option>
                              <template v-for="(roster) in rosterStatus">
                                <option :value="roster.id" :key="roster.id">{{ roster.name }}</option>
                              </template>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Player Tenure</label>
                            <select class="form-control" id="tenure" v-model="searchForm.tenure">
                              <option value="">Select Tenure</option>
                              <option v-for="(value, key) in tenureOptions" :value="value.value" :key="key">{{ value.text }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-12 modal-searchbtn text-center">
                          <div class="form-group">
                            <button type="submit" class="btn btn-primary btn-blue">{{ button.SearchButton }}</button>
                          </div>
                          <div class="text-sm text-danger">{{ errors.not_found }}</div>
                        </div>
                      </div>
                    </form>
                  </b-modal>
                </div>
              </div>
            </section>
          </div>
          <!-- /.row (main row) -->
        </div><!-- /.container-fluid -->
      </section>
    </div>
    <b-modal id="playerTransfer" ref="playerTransfer" title="Player Transfer"
             @hidden="resetTransferForm"
             @ok="handleTransferForm"
    >
      <form ref="form">
        <div class="form-group">
          <label for="reason" class="form-label">Reason <sup class="text-danger">*</sup></label>
          <textarea v-model="transferForm.reason" class="form-control" id="reason"></textarea>
          <span class="text-sm text-danger"></span>
        </div>
      </form>
    </b-modal>
    <!-- /.content -->
    <Footer></Footer>
  </div>
</template>

<script>

// import RegistrationsListTable from './RegistrationsListTable';
import $ from 'jquery';
// import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
// import 'datatables.net-responsive-dt';
import moment from 'moment';
import axios from "axios";


const validateName = name => {
  if (name.length || name != "") {
    return { valid: true, error: null };
  }
  return { valid: false, error: null };
};

export default {
  name: 'registrations',
  components: {},
  data() {
    return {
      table: '',
      exportProgress: false,
      filtersApplied: false,
      loaded: false,
      perPage: 10,
      currentPage: 1,
      transferForm: {
        id: '',
        status: '',
        reason: ''
      },
      searchForm: {
        confirmation_code:'',
        player_name: '',
        transfer_initiator: '',
        transfer_team_to: '',
        transfer_team_to_division: '',
        transfer_team_to_league: '',
        transfer_team_from: '',
        transfer_team_from_division: '',
        transfer_team_from_league: '',
        transfer_start_date: '',
        transfer_end_date: '',
        tenure:'',
        status: ''
      },
      teams: [],
      leagues:[],
      ageDivisions:[],
      button: {
        SearchButton: 'Search'
      },
      rosterStatus:[
        {
          id:0,
          name:"Pending"
        },
        {
          id:1,
          name:"Accepted"
        },
        {
          id:2,
          name:"Declined"
        }
      ],
      tenureOptions: [
        { value: 1, text: '1 month' },
        { value: 2, text: '2 months' },
        { value: 3, text: '3 months' },
        { value: 4, text: '4 months' },
        { value: 5, text: '5 months' },
        { value: 6, text: '6 months' },
        { value: 7, text: '7 months' },
        { value: 8, text: '8 months' },
        { value: 9, text: '9 months' },
        { value: 10, text: '10 months' },
        { value: 11, text: '11 months' },
        { value: 12, text: '12 months' },
      ],
    }
  },
  methods: {
    queryForKeywords(event) {
      const value = event.target.value
      this.keywords = value
      if(!isNaN(value) && value != '' && value.length == 9 & this.subdomain == 'alliancefastpitch'){
        this.searchForm.confirmation_code = value;
        this.advancedSearch();
      }else{
        this.searchForm.confirmation_code = '';
        this.table.search(value).draw();
      }
    },
    advancedSearch() {
      if(!this.validate()) {
        return;
      }
      this.button.SearchButton = 'Searching...';
      this.loaded = false;
      this.table.draw();
      this.filtersApplied = true;
      this.$refs['modal-member-report'].hide();
      this.button.SearchButton = 'Search';
    },
    formatDate(value) {
      var date = moment(value, "YYYY-MM-DD hh:mm:ss");
      if (date.isValid()) {
        return date.format('MM/DD/YYYY');
      } else {
        return 'N/A';
      }
    },
    approveForm(tid, status) {
      this.transferForm.id = tid;
      this.transferForm.status = status;
      this.$refs['playerTransfer'].show();
    },
    resetTransferForm() {
      this.transferForm.id = '';
      this.transferForm.status = '';
      this.transferForm.reason = '';
    },
    handleTransferForm(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      axios.post(this.basePath + 'api/admin/transferPlayer/request', this.transferForm, {headers: this.adminHeaders})
          .then(function (response) {
            this.alertMessage =  response.data.message;
            this.displayAlert = true;
            this.table.draw();
            this.$refs['playerTransfer'].hide()
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    },
    refreshList(event){
      this.searchForm = {
        confirmation_code:'',
        player_name: '',
        transfer_initiator: '',
        transfer_team_to: '',
        transfer_team_to_division: '',
        transfer_team_to_league: '',
        transfer_team_from: '',
        transfer_team_from_division: '',
        transfer_team_from_league: '',
        transfer_start_date: '',
        transfer_end_date: '',
        tenure:'',
        status: ''
      };
      // this.table.clear().draw();
      this.table.ajax.reload();
      this.filtersApplied = false;
    },
    exportCsv(){
      var self = this;
      $.ajax({
        url: process.env.VUE_APP_URL + "api/reports/export/player_transfer_history",
        type: 'post',
        "data": this.table.ajax.params(),
        beforeSend: function(){
          self.exportProgress = true;
        },
        "success": function(res, status, xhr) {
          self.exportProgress = false;
          if(res.file_url != undefined) window.open(res.file_url, '_blank');
        },
        error: function(err){
          console.log(err);
          self.exportProgress = false;
        }
      });
    },
    getLeague()
    {
      axios.get(this.basePath+'api/league', { headers: this.adminHeaders })
          .then((res) => {
            this.leagues = res.data.data;
          })
          .catch((error) => {
            console.log('Error:'+ error);
            return false;
          }).finally(() => {

          }
      );
    },
    getTeam()
    {
      axios.get(this.basePath+'api/get_teams', { headers: this.adminHeaders })
          .then((res) => {
            this.teams = res.data.data;
          })
          .catch((error) => {
            console.log('Error:'+ error);
            return false;
          }).finally(() => {

          }
      );
    },
    validate() {
      this.errors = {}
      this.valid =  true;
      const validStartDate = validateName(this.searchForm.transfer_start_date);
      this.errors.transfer_start_date = validStartDate.error;

      const validEndDate = validateName(this.searchForm.transfer_end_date);
      this.errors.transfer_end_date = validEndDate.error;

      var dateValid = false;
      if(validStartDate.valid && !this.validateDate(this.searchForm.transfer_start_date)){
        dateValid = true;
        this.errors.transfer_start_date = "Invalid Date";
      }
      if(validEndDate.valid && !this.validateDate(this.searchForm.transfer_end_date)){
        dateValid = true;
        this.errors.transfer_end_date = "Invalid Date";
      }

      if(dateValid == false){
        if((validStartDate.valid && validEndDate.valid) && (moment(this.searchForm.transfer_end_date).isBefore(this.searchForm.transfer_start_date))) {
          this.errors.transfer_end_date = 'Transfer To date must be greater than Transfer From date!';
          return this.valid =  false;
        } else {
          this.valid = true;
        }
      } else {
        this.valid = false;
      }

      return this.valid;
    },
    Search(event) {
      this.term = this.$route.query.term;
      let email = '';
      let member_id = '';
      let name = '';
      if(this.term !== undefined) {
        if (this.term.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
          email = this.term;
        } else if(this.term.match(/^[0-9]+$/) && this.term != '') {
          member_id = this.term;
        } else {
          name = this.term;
        }
        this.searchForm.year = "";
        this.searchForm.confirmation_code = member_id;
        this.searchForm.player_name = name;
        this.advancedSearch();
      }
    },
  },
  mounted() {
    this.getTeam();
    this.getLeague();
    this.getDiscipline();
    let self = this;
    this.table = $("#list_table").DataTable({
      dom: "lfrtip",
      processing: true,
      serverSide: true,
      bFilter: true,
      responsive: true,
      ajax: {
        url: process.env.VUE_APP_URL + "api/getTransferHistory",
        type: 'post',
        data: function (d) {
          d.transfer_confirmation_code = $('#confirmation_code').val() || self.searchForm.confirmation_code;
          d.transfer_player_name = $('#player_name').val() || self.searchForm.player_name;
          d.transfer_initiator = $('#transfer_initiator').val() || self.searchForm.transfer_initiator;
          d.transfer_team_to = $('#transfer_team_to').val() || self.searchForm.transfer_team_to;
          d.transfer_start_date = $('#transfer_start_date').val() || self.searchForm.transfer_start_date;
          d.transfer_end_date = $('#transfer_end_date').val() || self.searchForm.transfer_end_date;
          d.transfer_team_from_league = $('#transfer_team_from_league').val() || self.searchForm.transfer_team_from_league;
          d.transfer_team_to_league = $('#transfer_team_to_league').val() || self.searchForm.transfer_team_to_league;
          d.transfer_team_from_division = $("#transfer_team_from_division").val() || self.searchForm.transfer_team_from_division;
          d.transfer_team_to_division = $('#transfer_team_to_division').val() || self.searchForm.transfer_team_to_division;
          d.transfer_status = $('#status').val() || self.searchForm.status;
          d.transfer_tenure = $('#tenure').val() || self.searchForm.tenure;
        }
      },
      columns: [
        {
          data: 'member.confirmation_code', name: 'member.confirmation_code', render: (data) => {
            return data || "N/A";
          }
        },
        { data: "member.personal_details.first_name", name: "member.personal_details.first_name", render: (data)=>{return data || "N/A";}, visible: false },
        { data: "member.personal_details.last_name", name: "member.personal_details.last_name", render: (data)=>{return data || "N/A";}, visible: false },
        { data: "player_name", name: "member.personal_details.first_name", render: (data)=>{return data || "N/A";} },
        {
          data: 'source_name', name: 'currentTeam.name', render: (data) => {
            return data || "N/A";
          }
        },
        {
          data: 'source_team_member_id', name: 'currentTeam.members.confirmation_code', render: (data) => {
            return data || "N/A";
          }
        },
        {
          data: 'exist_age_division', name: 'currentTeam.ageDivision.name', render: (data) => {
            return data || "N/A";
          }
        },
        {
          data: 'transferred_team', name: 'team.name', render: (data) => {
            return data || "N/A";
          }
        },
        {
          data: 'new_team_member_id', name: 'newTeam.members.confirmation_code', render: (data) => {
            return data || "N/A";
          }
        },
        {
          data: 'new_age_division', name: 'team.ageDivision.name', render: (data) => {
            return data || "N/A";
          }
        },
        {
          data: 'transfer_initiate_at', name: 'transfer_initiate_at', render: (data) => {
            return data || "N/A";
          }, searchable: false
        },
        {
          data: 'tenure', name: 'tenure',  searchable: false, render: (data) => {
            return data || "";
          }
        },
        {
          data: 'status', name: 'status', render: (data) => {
            return data || "";
          }
        },
      ],
      order: [[0, "desc"]]
    });
    var vueRouter = this.$router;
    document.addEventListener("click", function (e) {
      if (e.target && e.target.parentNode.className == "action-approve") {
        var aid = e.target.parentNode.getAttribute('data-id');
        window.reg.approveForm(aid, 1);
      }
      if (e.target && e.target.parentNode.className == "action-reject") {
        var rid = e.target.parentNode.getAttribute('data-id');
        window.reg.approveForm(rid, 2);
      }
    });
    this.Search();
  },

  computed: {}
}
</script>